.link {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: currentColor;
  }

  &.is-active {
    color: $color-primary;
    border-bottom: 1px solid $color-primary;
  }
}

.link-more {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: $white;
  line-height: 20px;
}

.link-more__icon {
  animation: bounce 0.5s infinite alternate-reverse;
}

.link-icon {
  color: $black;
  border-bottom: 1px solid currentColor;

  svg {
    position: relative;
    top: 1px;
    margin-left: 4px;

    path {
      transition: fill 0.3s $timing-cubic;
    }
  }

  &:hover {
    color: $black-secondary;

    svg path {
      fill: $black-secondary;
    }
  }

}

@keyframes bounce {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 5px);
  }
}