.accordion {
  .accordion-link {
    display: inline-flex;
    align-items: center;
    margin-top: $spacing-2;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    user-select: none;
    border-bottom: 1px solid currentColor;
    svg {
      position: relative;
      top: 2px;
      margin-left: $spacing-1;
      transform: rotate(90deg);
      path {
        transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
      }
    }
    &:hover {
      color: $black-tertiary;
  
      svg {
        path {
          fill: $black-tertiary;
        }
      }
    }
  }

  .accordion-more {
    display: none;
  }

  &.is-opened {
    .accordion-link {
      svg {
        transform: rotate(-90deg);
      }
    }
    .accordion-more {
      display: flex;
      flex-direction: column;
    }
  }

}