.banner {
	position: relative;
	width: 100%;
	height: 100vh;
	min-height: 500px;
	max-height: 1080px;

	.link-more {
		position: absolute;
		bottom: 75px;
		z-index: 1;
	}
}

.banner__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
		user-select: none;
		object-position: 50% 50%;
	}

	.lazy-load:not(.lazy-load--placeholder) {
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(rgba($black, 0.5), rgba($black, 0.3), transparent, rgba($black, 0.4));
			z-index: 1;
		}
	}
}

.banner__background--dark {
	background: rgba($black, 0.4);

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(rgba($black, 0.4), transparent);
		z-index: 1;
	}
}

.banner__content {
	position: relative;
	z-index: 1;
	top: -60px;
	text-align: center;

	p, h2 {
		br {
			display: none;
		}
	}

	h2 {
		margin-left: 2px;
		margin-right: 2px;
	}

	p {
		margin-left: $spacing-3;
		margin-right: $spacing-3;
	}

}

.banner--small {
	height: 70vh;
	min-height: 300px;
	.banner__content {
		top: 0;
	}
}

.banner--color {
	height: 100%;
	min-height: initial;
	max-height: initial;
	.banner__content {
		top: 0;
		padding-top: 137px;
		padding-bottom: 165px;
	}

	.banner__color {
		background: $background-dark;
		width: 100%;
	}
}

.banner__image {
	margin-top: -100px;
	.lazy-load {
		padding-top: 58.30769%;
	}
}

.banner-text {
	padding-top: 123px;
}

@media (min-width: $grid-breakpoints-md) {
	.banner--color {
		.banner__content {
			padding-top: 171px;
			padding-bottom: 259px;
		}
	}
	.banner__image {
		.lazy-load {
			margin-top: -189px;
		}
	}
	.banner-text {
		padding-top: 176px;
	}
	.banner__content {
		p, h2 {
			margin-left: 0;
			margin-right: 0;

			br {
				display: block;
			}
		}
	}
}

@media (min-width: $grid-breakpoints-lg) {
	.banner--color {
		.banner__content {
			padding-top: 205px;
			padding-bottom: 436px;
		}
	}
	.banner__image {
		.lazy-load {
			overflow: hidden;
			margin-top: -324px;
			padding-top: 50.70203%;
			img {
				object-fit: cover;
			}
		}
	}
	.banner-text {
		padding-top: 200px;
	}
}
