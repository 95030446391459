@charset 'UTF-8';

@import 'base/variable';
@import 'base/fonts';
@import 'base/grid';
@import 'base/reset';

@import 'object/alignement';
@import 'object/margin';
@import 'object/typography';
@import 'object/button';
@import 'object/link';
@import 'object/section';
@import 'object/quote';
@import 'object/mosaic';
@import 'object/animation';
@import 'object/form';

@import 'component/footer';
@import 'component/menu';
@import 'component/card';
@import 'component/banner';
@import 'component/lazy-load';
@import 'component/blocks';
@import 'component/card-testimonial';
@import 'component/card-prestation';
@import 'component/carousel';
@import 'component/navbar';
@import 'component/accordion';

.wrapper {
	position: relative;
}
