.f {
  display: flex;
}

.f-inline {
  display: inline-flex;
}

.f-direction-column {
  flex-direction: column;
}

.f-direction-row-reverse {
  flex-direction: row-reverse;
}

.f-align-items-center {
  align-items: center;
}

.f-jc-center {
  justify-content: center;
}

.f-jc-right {
  justify-content: flex-end;
}

.f-jc-space-b {
  justify-content: space-between;
}

.f-jc-space-a {
  justify-content: space-around;
}

.f-jc-space-c {
  justify-content: center;
}

.f-ai-baseline {
  align-items: baseline;
}

@media (min-width: $grid-breakpoints-md) {
  .f--tablet {
    display: flex;
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .f--desktop {
    display: flex;
  }
}