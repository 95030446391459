.card-prestation {
	background: $white;
	border-radius: $radius;
	padding: $spacing-4 $spacing-3;
	border: 2px solid $black-quinary;
	box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.03);

	.block-icon {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $spacing-2;
	}
}
