.menu {
	display: flex;
	align-items: center;
	width: 100%;
	height: 90px;
	position: absolute;
	top: 0;
	z-index: 3;

	.container {
		position: relative;
		display: flex;
		align-items: center;
	}

	h1 {
		display: none;
	}
}

.menu__mobile {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: $spacing-2;

	span {
		display: block;
		width: 25px;
		height: 2px;
		background: $white;
		margin-bottom: 6px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menu__logo {
	display: flex;
	margin-right: $spacing-4;

	img {
		position: relative;
		top: 4px;
		width: 200px;
		min-width: 200px;
	}
}

.menu__links {
	position: relative;
	display: inline-flex;
	opacity: 0;
	pointer-events: none;
	z-index: -9999;
	transition: 0.2s background-color $timing-cubic;
}

.menu__btn {
	display: none;
	position: absolute;
	right: $spacing-3;
}

.menu {
	&.is-open {
		.menu__links {
			display: flex;
			flex-direction: column;
			position: fixed;
			top: 0;
			left: 0;
			opacity: 1;
			pointer-events: initial;
			z-index: 4;
			width: 100%;
			height: 100vh;
			background-color: $white;
			padding-top: $spacing-4;
			padding-left: $spacing-3;

			a {
				color: $black;
				font-size: $fts-large;
				margin-bottom: $spacing-3;
			}

			img {
				width: 200px;
				min-width: 200px;
				margin-bottom: $spacing-6;
			}
		}

		.menu__mobile {
			position: fixed;
			top: 45px;
			z-index: 5;

			span {
				background: $black;

				&:first-child {
					position: relative;
					top: 8px;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					position: relative;
					transform: rotate(-45deg);
				}

				&:last-child {
					display: none;
				}
			}
		}
	}
}

.menu__logo--black {
	display: none;
}

.menu--black {
	.menu__logo--white {
		display: none;
	}
	.menu__logo--black {
		display: block;
	}
	.menu__links a {
		color: $black;
	}
	.menu__mobile span {
		background: $black;
	}
}

@media (min-width: $grid-breakpoints-md) {
	.menu__logo {
		img {
			width: 220px;
			min-width: 220px;
		}
	}
}

@media (min-width: $grid-breakpoints-menu) {
	.menu {
		transition: 0.3s transform $timing-cubic, 0.3s opacity $timing-cubic;
		height: 100px;

		&.is-sticky {
			position: fixed;
			top: 0;
			left: 0;
			background: $white;
			height: 100px;
			box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2);

			.menu__logo--white {
				display: none;
			}

			.menu__logo--black {
				display: block;

				img {
					top: 2px;
					width: 200px;
					min-width: 200px;
				}
			}

			.menu__btn {
				display: block;
			}

			.menu__links {
				a {
					color: $black;
				}
			}
		}

		&.is-down {
			opacity: 0;
			transform: translateY(-80px);
		}

		&.is-up {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.menu__mobile {
		display: none;
	}

	.menu__links {
		opacity: 1;
		pointer-events: initial;
		z-index: 0;

		img {
			display: none;
		}

		li {
			&:nth-child(1),
			&:nth-child(2) {
				display: none;
			}
		}

		a {
			color: $white;
			margin: 0 $spacing-2;
		}
	}
}
