.block {
	width: 100%;

	.lazy-load {
		min-height: 150px;
		height: 150px;
		border-radius: $radius;
		overflow: hidden;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.12);
	}
}

a.block {
	text-decoration: none;

	h3 {
		transition: 0.3s color $timing-cubic;
	}

	.lazy-load {
		will-change: transform;
		transition: 0.3s transform $timing-cubic, 0.2s box-shadow $timing-cubic;
	}

	&:hover {
		.lazy-load {
			box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.10);
			transform: translateY(-4px);
		}

		h3 {
			color: $color-primary;
		}
	}
}

.block--link {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid $black-quinary;
	border-radius: $radius;
	color: $black;
	background-color: $white-quaternary;
	min-height: 150px;
	height: 150px;
	&:hover {
		box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.03);
		background-color: $white-tertiary;
		transform: translateY(-4px);
		color: $color-primary;
	}
}

.block-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	width: 48px;
	height: 48px;
	border-radius: 48px;
	background: $light-border;
}

.block-icon--white {
	background: $white;
}

.block-prestation {
	background: $white;
	border-radius: $radius;
	padding: $spacing-2 0;
	border: 2px solid $black-quinary;
	color: $black;
	h3,
	.block-icon,
	svg path {
		transition: all 0.3s $timing-cubic;
	}
	&:hover {
		box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.03);
		background-color: $white-tertiary;
		transform: translateY(-4px);
		h3 {
			color: $color-primary;
		}
		.block-icon {
			background: $black-quinary;
		}
		svg path {
			fill: $color-primary;
		}
	}
}

@media (min-width: $grid-breakpoints-md) {
	.block {
		.lazy-load {
			min-height: 200px;
			height: 200px;
		}
	}
	.block-prestation {
		padding: $spacing-5 0;
	}
	.block--link {
		min-height: 200px;
		height: 200px;
		margin-bottom: 38px;
	}
}
