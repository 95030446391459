@media (max-width: $grid-breakpoints-md - 1) {
  .swiper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    list-style: none;
    z-index: 1;
  }

  .swiper-container-vertical>.swiper-wrapper {
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  .swiper-container-multirow>.swiper-wrapper {
    flex-wrap: wrap;
  }

  .swiper-container-multirow-column>.swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .swiper-container-free-mode>.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    max-width: 364px + $spacing-2 + $spacing-2;
  }

  /* Auto Height */
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }

  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    margin-top: $spacing-3;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: rgba($color-primary, 0.16);
    border-radius: $radius;
    margin-right: $spacing-1;

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: $color-primary;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .swiper-slide {
    max-width: 100%;
    padding: 0;
    margin-right: 0;

    .card-testimonial {
      min-height: 100%;
    }

    &:first-child {
      margin-right: $spacing-3;
    }

    &:last-child {
      display: none;
    }
  }

  .swiper-wrapper {
    display: flex;
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .swiper-slide {
    max-width: 364px;
    margin-right: $spacing-4;

    .card-testimonial {
      min-height: inherit;
    }

    &:last-child {
      margin-right: 0;
      display: block;
    }
  }
}