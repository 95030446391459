/* Spacing */
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 40px;
$spacing-6: 48px;
$spacing-7: 56px;
$spacing-8: 64px;
$spacing-9: 72px;

/* Font familly */
$ftf-default: 'Moderat';
$ftf-heading: $ftf-default;

/* Font Size */
$fts-default: 16px;
$fts-large: 18px;
$fts-small: 14px;
$fts-xs: 12px;

/* Main colors */
$color-primary: #bf2c2c;
$color-primary-focus: #9c2a2a;

$color-secondary: #243342;
$color-tertiary: #2c3e50;

/* Black and white */
$black: #23262d; // OK
$black-secondary: rgba($black, 0.72);
$black-tertiary: rgba($black, 0.56);
$black-quaternary: rgba($black, 0.32);
$black-quinary: rgba($black, 0.06);

$white: #fff; // OK
$white-secondary: rgba($white, 0.72);
$white-tertiary: rgba($white, 0.56);
$white-quaternary: rgba($white, 0.32);
$white-quinary: rgba($white, 0.06);

/* Border */
$light-border: #eeeeee;
$dark-border: rgba($white, 0.12);

/* Background */
$background: #f7f4ec;
$background-dark: #170c0c;

/* Size */
$radius: 4px;
$radius-small: 2px;

/* Shadow */
$shadow1: 0 2px 10px rgba(51, 51, 51, 0.08);
$shadow2: 0 8px 16px rgba(51, 51, 51, 0.08);

/* Transition */
$timing-cubic: cubic-bezier(0.25, 0.1, 0.25, 1); // OK

/* z-index */
$z-layers: (
	'overlay': 200,
	'has-overlay': 201,
	'header': 10,
	'wrapper-mobile': 2,
	'topbar': 3,
	'menu': 2,
	'menu-club': 4,
	'filter': 1,
	'cookie': 9
);

@function z($layer) {
	@if not map-has-key($z-layers, $layer) {
		@warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
	}

	@return map-get($z-layers, $layer);
}
