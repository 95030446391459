.section {
	position: relative;
	padding-top: $spacing-8;
	padding-bottom: $spacing-8;

	.lazy-load {
		width: 100%;
		min-height: 200px;
	}

	img {
		border-radius: $radius;
		object-fit: cover;
	}
}

.section--white {
	background-color: $white;
}

.section--black {
	background-color: $color-secondary;
	color: $white;
}

.section--img-right {
	.row {
		display: flex;
		flex-direction: column-reverse;
		text-align: left;
	}
}

.section.section--no-p-top {
	padding-top: $spacing-6;
}

.section--last {
	padding-bottom: $spacing-8+$spacing-4;
}

.section--prestation-more {
	.lazy-load {
		min-height: 100px;
	}
}

.section--prestation {
	ul {
		list-style: disc;
		padding-left: 18px;
	}
}

// .section--prestation-more {
// 	ul {
// 		display: grid;
// 		grid-template-columns: repeat(2, 1fr);
// 		grid-column-gap: $spacing-2;
// 		grid-row-gap: $spacing-2;
// 	}
// 	li {
// 		display: flex;
// 		align-items: center;
// 	}
// 	.block-icon {
// 		margin-left: 0;
// 		margin-right: $spacing-2;
// 	}
// }

@media (min-width: $grid-breakpoints-md) {
	.section {
		padding-top: $spacing-9;
		padding-bottom: $spacing-9;

		.lazy-load {
			min-height: 350px;
		}
	}

	.section--img-right,
	.section--img-left {
		.row {
			flex-direction: initial;
		}

		.col:last-child {
			padding-left: $spacing-4;
		}
	}

	.section--last {
		padding-bottom: $spacing-9+$spacing-4;
	}

	.section.section--no-p-top {
		padding-top: $spacing-7;
	}

	.section--prestation {
		.lazy-load {
			height: 500px;
		}
	}
}

@media (min-width: $grid-breakpoints-lg) {
	.section {
		padding-top: 100px;
		padding-bottom: 100px;

		.lazy-load {
			min-height: 600px;
		}
	}

	.section--contact {
		padding-top: $spacing-9;
	}

	.section--img-right,
	.section--img-left {
		.col:last-child {
			padding-left: initial;
		}
	}

	.section--last {
		padding-bottom: 100px+$spacing-4;
	}
	.section--prestation-more {
		.lazy-load {
			min-height: 400px;
		}
	}
}
