h1,
.h1 {
  font-size: 40px;
  line-height: 48px;
}

h2,
.h2 {
  font-size: 24px;
  line-height: 36px;
}

h3,
.h3 {
  font-size: $fts-large;
  line-height: 22px;
}

h4,
.h4 {
  font-size: 15px;
  line-height: 20px;
}

h5,
.h5 {
  font-size: $fts-small;
  line-height: 20px;
}

h6,
.h6 {
  font-size: $fts-xs;
  line-height: 20px;
}

// Copy
.ft-primary {
  font-size: $fts-large;
  line-height: 32px;
}

.ft-secondary {
  font-size: $fts-small;
  line-height: 24px;
}

.ft-tertiary {
  font-size: $fts-xs;
  line-height: 20px;
}

// Helper
.ft-up {
  text-transform: uppercase;
}

.ft-center {
  text-align: center;
}

.ft-italic {
  font-style: italic;
}

.ft-nowrap {
  white-space: nowrap;
}

.ft-400 {
  font-weight: 400;
}

.ft-700 {
  font-weight: 700;
}

.ft-truncat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-black {
  color: $black;
}

.c-black-secondary {
  color: $black-secondary;
}

.c-black-tertiary {
  color: $black-tertiary;
}

.c-white {
  color: $white;
}

.c-white-secondary {
  color: $white-secondary;
}

.c-white-tertiary {
  color: $white-tertiary;
}

.c-white-quaternary {
  color: $white-quaternary;
}

.c-white {
  color: $white;
}

.c-primary {
  color: $color-primary;
}

@media (min-width: $grid-breakpoints-md) {
  .h1--tablet {
    font-size: 40px;
    line-height: 48px;
  }
}