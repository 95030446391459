.card {
  width: 100%;
  background: $white;
  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s $timing-cubic;

  .card__header,
  .card__footer {
    min-height: 30px;
  }

  .card__header {
    overflow: hidden;
  }

  img {
    transition: transform 0.3s $timing-cubic;
    transform: scale(1.02);
  }

  &:hover {
    .card__header img {
      transform: scale(1);
    }
    box-shadow: $shadow1;
  }

  .card__body {
    padding: $spacing-2;

    span {
      font-size: 68px;
      line-height: 68px;
    }
  }

  .card__footer {
    padding: $spacing-2;
    border-top: 1px solid $black-quinary;
  }
}
