.lazy-load {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  &.lazy-load--placeholder {
    .lazy-load__placeholder {
      opacity: 1;
    }

    .lazy-load__image {
      opacity: 0;
      transform: scale(1.05);
    }
  }

}

.lazy-load__placeholder {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.lazy-load__image {
  opacity: 1;
  transform: scale(1);
  will-change: transform;
  transition: opacity 0.5s ease-in-out,
    transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}