.input {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: $spacing-1;
  }
  input,
  textarea {
    display: block;
    height: 56px;
    padding: 0 $spacing-3;
    outline: 0;
    background: #efebdf;
    border: 1px solid transparent;
    transition: background 0.3s $timing-cubic;
    &:hover {
      background: #e6e1d5;
    }
  }
  textarea {
    padding: $spacing-3;
    height: 200px;
    resize: none;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .input-group {
    display: flex;
    justify-content: space-between;
    .input {
      width: calc(50% - #{$spacing-2});
    }
    .input--full {
      width: 100%;
    }
  }
}
