.mosaic {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: $spacing-5;
	grid-row-gap: $spacing-5;
	text-align: center;
}

.mosaic--prestation-more {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	grid-column-gap: 0;
	grid-row-gap: 0;

	> div,
	a {
		flex: 0 0 calc(50% - #{$spacing-3});
		margin-right: $spacing-5;
		margin-bottom: $spacing-4;
		&:nth-child(2n),
		&:last-child {
			margin-right: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.mosaic--prestation {
	grid-template-columns: repeat(1, 1fr);
}

@media (min-width: $grid-breakpoints-md) {
	.mosaic {
		grid-row-gap: $spacing-7;
	}
	.mosaic--prestation {
		grid-template-columns: repeat(2, 1fr);
	}
	.mosaic--prestation-more {
		grid-row-gap: 0;
		> div,
		a {
			flex: 0 0 25%;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(2n) {
				margin-right: $spacing-5;
			}
			&:last-child {
				margin-bottom: $spacing-4;
			}
		}
	}
}

@media (min-width: $grid-breakpoints-lg) {
	.mosaic:not(.mosaic--prestation-more) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		grid-row-gap: 0;
		grid-column-gap: 0;

		> div,
		a {
			flex: 0 0 calc(100% / 3 - #{$spacing-5});
			margin-right: $spacing-5;
			margin-bottom: $spacing-7;
			&:nth-child(3n),
			&:last-child {
				margin-right: 0;
			}
			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}
		}
	}

	// .mosaic--prestation-more {
	// 	display: grid;
	// 	grid-row-gap: $spacing-3;
	// 	grid-column-gap: $spacing-5;
	// 	grid-template-columns: repeat(5, 1fr);
	// 	> div,
	// 	a {
	// 		margin-right: 0;
	// 		&:nth-child(2n) {
	// 			margin-right: 0;
	// 		}
	// 	}
	// }
}
