.card-testimonial {
  min-width: 0;
  padding: $spacing-4 $spacing-3;
  margin: 0;
  background: $white;
  border-radius: $radius;
  color: $black;
  box-shadow: 0 0 56px 8px rgba($color-primary, 0.04),
    0 4px 64px 4px rgba(0, 0, 0, 0.04);

  .card-testimonial__body {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .card-testimonial__desc {
    font-size: 20px;
    line-height: 32px;
  }

  .card-testimonial__author {
    width: percentage(224 / 316);

    .ft-primary {
      line-height: 22px;
    }

  }

  .card-testimonial__img {
    padding-top: 4px;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }

  .card-testimonial__bottom {
    display: flex;
    padding-top: $spacing-4;
    border-top: 1px solid $light-border;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .card-testimonial {
    min-height: 367px;
  }
}