@font-face {
  font-family: 'Moderat';
  src: url('../fonts/moderat-regular.woff') format('woff'),
    url('../fonts/moderat-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Moderat';
  src: url('../fonts/moderat-bold.woff') format('woff'),
    url('../fonts/moderat-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
