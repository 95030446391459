.footer {
	color: $white;
	background-color: $background-dark;
	padding-top: $spacing-7;
	padding-bottom: $spacing-7;
}

.footer__links {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: $spacing-5;

	img {
		width: 200px;
		min-width: 200px;
	}

	h3 {
		color: $white;
	}

	a {
		color: $white;
	}
}

.footer__pages {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: $spacing-1 $spacing-2;
}

.footer__social {
	display: flex;
	margin-top: $spacing-2;
	margin-left: -4px;
	a {
		display: flex;
		align-items: center;
		justify-content: center;

		svg path {
			transition: all 0.3s $timing-cubic;
			fill: $white;
		}

		&:hover {
			svg path {
				fill: $color-primary;
			}
		}
	}
}

@media (min-width: $grid-breakpoints-md) {
	.footer {
		padding-top: $spacing-8;
		padding-bottom: $spacing-9;
	}

	.footer__links {
		grid-template-columns: repeat(3, 1fr);

		img {
			width: 220px;
			min-width: 220px;
		}
	}
}
