// Grid
$grid-breakpoints-menu: 800px;
$grid-breakpoints-md: 768px; // Medium screen / tablet
$grid-breakpoints-lg: 1240px; // Large screen / desktop
$grid-columns-mobile: 4;
$grid-columns-tablet: 12;
$grid-columns-desktop: 12;
// $grid-columns-xl: 16;
$grid-gutter-sm: 16px;
$grid-gutter-md: 24px;
$grid-gutter-lg: 32px;
$max-width: 1420px;
$offset-enable: false !default; // Turn this value to false if you don't want to use offset

$menu-breakpoint: 980px; // Medium screen / tablet


@mixin make-col($columns, $grid-columns) {
  width: percentage(($columns / $grid-columns));
}

@mixin make-offset($columns, $grid-columns) {
  margin-left: percentage(($columns / $grid-columns));
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.row {
  margin-left: -$grid-gutter-sm;

  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

.row--white {
  background-color: $white;
}

.row--flex {
  display: flex;
  flex-direction: column;
}

.col {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: $grid-gutter-sm;
  width: 100%;
}

.col--center {
  margin-left: auto;
  margin-right: auto;
  float: none;
}


@for $i from 1 to $grid-columns-mobile {
  .col-xs-#{$i} {
    @include make-col($i, $grid-columns-mobile);
  }
}

@media (min-width: $grid-breakpoints-md) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .row {
    margin-left: -$grid-gutter-md;
  }

  .row--flex {
    flex-direction: row;
  }

  .col {
    padding-left: $grid-gutter-md;
  }

  @for $i from 1 to $grid-columns-tablet+1 {
    .col-md-#{$i} {
      @include make-col($i, $grid-columns-tablet);
    }

    @if $offset-enable {
      .col-md-offset-#{$i} {
        @include make-offset($i, $grid-columns-tablet);
      }
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .container {
    max-width: $max-width;
    padding-left: 32px;
    padding-right: 32px;
  }

  .row {
    margin-left: -$grid-gutter-lg;
  }

  .col {
    padding-left: $grid-gutter-lg;
  }

  @for $i from 1 to $grid-columns-desktop+1 {
    .col-lg-#{$i} {
      @include make-col($i, $grid-columns-desktop);
    }

    @if true {
      .col-lg-offset-#{$i} {
        @include make-offset($i, $grid-columns-desktop);
      }
    }
  }

  .col-lg-offset-1 {
    @include make-offset(1, $grid-columns-desktop);
  }
}

// DEBUG CODE
.grid-debug {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
  display: none;

  &.is-visible {
    display: block;
    opacity: .4;
  }

  .container {
    height: 100%;
  }

  .row {
    height: 100%;
  }

  .col {
    height: 100%;
    background: rgba(27, 122, 181, .3);

    div {
      height: 100%;
      background: rgba(27, 122, 181, .3);
    }
  }
}