.barba-leave-active,
.barba-enter-active {
  transition: opacity 0.2s ease-in-out;
}

.barba-leave,
.barba-enter-to {
  opacity: 1;
}

.barba-enter,
.barba-leave-to {
  opacity: 0;
}