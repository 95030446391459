.btn {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  border-radius: $radius;
  font-weight: 500;
  transition: all 0.2s $timing-cubic;
  cursor: pointer;
  color: $black;
  padding: $spacing-2 $spacing-3;
  outline: none;
  text-decoration: none;
  background-color: $white;

  &.is-disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover,
  &:focus {
    background-color: rgba($color-primary, 0.12);
  }
}

.btn--small {
  height: 40px;
  line-height: 40px;
}

.btn--icon-left {
  i {
    margin-left: 0;
    margin-right: $spacing-3;
  }
}

.btn--full {
  width: 100%;
}

.btn--primary {
  color: $white;
  border: 0;
  background-color: $color-primary;

  &:hover,
  &:focus {
    background-color: $color-primary-focus;
  }
}

.btn--secondary {
  &:hover,
  &:focus {
    border-color: $color-primary;
    color: $color-primary;
    // background-color: rgba($color-primary, .12);
  }
}
