.navbar {
	position: -webkit-sticky;
	position: sticky;
	top: -1px;
	width: 100%;
	height: 72px;
	background: $white;
	overflow-x: scroll;
	overflow-y: hidden;
	z-index: 2;
	box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.2);
	transition: 0.3s top $timing-cubic;
	will-change: top;

	ul {
		display: flex;
		align-items: center;
		height: 72px;
		white-space: nowrap;

		@media (min-width: $grid-breakpoints-lg) {
			justify-content: center;
			white-space: initial;
		}
	}

	li {
		padding: 0 16px;

		&.is-active {
			a {
				color: $color-primary;
				border-color: $color-primary;
			}
		}
	}

	a {
		color: $black;
	}
}

@media (min-width: $grid-breakpoints-menu) {
	.navbar {
		&.with-menu {
			top: 99px;
			border-top: 1px solid $light-border;
		}
	}
}
