.quote {
  blockquote {
    color: $black;
    line-height: 28px;
    font-family: $ftf-heading;
  }

  .quote__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(120, 48, 254, 0.04),
      0px 4px 16px rgba(0, 0, 0, 0.06);
  }

}

@media (min-width: $grid-breakpoints-md) {
  .quote {
    blockquote {
      font-size: 20px;
      line-height: 34px;
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .quote {
    blockquote {
      font-size: 24px;
      line-height: 42px;
    }
  }
}